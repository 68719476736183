.navbar {
    background-color: #3445b4 !important;
}

.tituloHomeSmall {
    display: none;
}

.registro-menu {
    display: none;
}

.finanzas-menu {
    display: none;
}

.direccionestudiantil-menu {
    display: none;
}

.ccesen-menu {
    display: none;
}

.noti-menu {
    display: none;
}

.catalogoResult {
    display: none;
}

@media only screen and (max-width: 650px) {
    [class*="tituloHomeBig"] {
        display: none;
    }
    [class*="alertaAviso"] {
        display: none;
    }
    [class*="tituloHomeSmall"] {
        display: inline;
    }
}

@font-face {
    font-family: 'letradecarta';
    /* src: url('./font/Sriracha-Regular.ttf') format('truetype') */
    src: url('./font/NotoSans-Regular.ttf') format('truetype')
    /* src: url('./font/NotoSansJP-Regular.otf') format('truetype') */
}

.letradecarta {
    font-family: 'letradecarta';
    font-size: 17px;
    text-align: justify;
}

.homeLogin {
    font-family: 'letradecarta';
}

div,
p,
table,
td,
h2,
h1,
h4,
h3 {
    font-family: 'letradecarta';
}

.fondoBalck {
    margin-top: 1px;
    margin-bottom: 1px;
    background: rgb(51, 50, 50);
    opacity: 0.8;
    border-radius: 30px;
    padding: 0px;
}