::-webkit-input-placeholder {
    color: pink !important;
}


/* Chrome/Opera/Safari */

::-moz-placeholder {
    color: pink !important;
}


/* Firefox 19+ */

:-ms-input-placeholder {
    color: pink !important;
}


/* IE 10+ */

:-moz-placeholder {
    color: pink !important;
}


/* Firefox 18- */