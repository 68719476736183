.erecursosLogos {
    width: 25%;
    border-radius: 15px;
}

.erecursosDescripcion {
    display: none;
}

.erecursosDescripcionLook {
    display: block;
}

@media only screen and (max-width: 650px) {
    [class*="erecursosLogos"] {
        width: 100%;
    }
}